<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.deliveryDiscipline')" />

    <v-card-text class="pb-0">
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- Entry Info section -->
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.deliveryDiscipline") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <!-- Order -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="3">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.order')"
                readonly
                :rules="[rule.required]"
                v-model="myObj.po"
                :append-icon="'mdi-account-search'"
                @click:append="handleDialogOrder"
                :disabled="!isBtnSearchOrder"
              />
            </v-col>

            <!-- List Box -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-select
                :items="ship_way"
                :label="$vuetify.lang.t('$vuetify.sendWay')"
                v-model="myObj.send_way"
              />
            </v-col>

            <!-- Delivery Date -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="3">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    append-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.dateOfDelivery')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-model="myObj.send_date"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.send_date"
                  @input="menu = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>
          </v-row>

          <!-- Product List -->
          <v-row
            v-if="
              SelectedOrder != undefined && SelectedOrder.products != undefined
            "
          >
            <v-col class="py-0" cols="12">
              <v-data-table
                class="elevation-4 my-4"
                :headers="headers"
                :items="SelectedOrder.products"
              >
                <template v-slot:[`item.number`]="{ item }">
                  <v-text-field
                    v-model.number="item.number"
                    type="number"
                    :suffix="'/' + item.number"
                  />
                </template>

                <template v-slot:[`item.net_weight`]="{ item }">
                  <v-text-field v-model="item.net_weight" />
                </template>

                <template v-slot:[`item.gross_weight`]="{ item }">
                  <v-text-field v-model="item.gross_weight" />
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <!-- Arv-Time -->
            <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    append-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.plannedArrival')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-model="myObj.plan_receive"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.plan_receive"
                  @input="menu1 = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <!-- Arv Time 1 -->
            <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    append-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.plannedArrivalR1')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-model="myObj.plan_receive_r1"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.plan_receive_r1"
                  @input="menu2 = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <!-- Arv Time 2 -->
            <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    append-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.plannedArrivalR2')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-model="myObj.plan_receive_r2"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.plan_receive_r2"
                  @input="menu3 = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <!-- Arv Time -->
            <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    append-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.actualArrival')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    disabled
                    v-model="myObj.real_receive"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.real_receive"
                  @input="menu4 = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="4" sm="6">
              <v-text-field
                style="color: black"
                label="Carton Number"
                v-model="myObj.carton_num"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="4" sm="6">
              <v-text-field
                style="color: black"
                label="Domestic Freight"
                v-model.number="myObj.shipping_cost1"
                type="number"
                prefix="$"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="6" md="4" sm="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.totalFreight')"
                v-model="myObj.shipping_cost1"
                prefix="$"
                disabled
              />
            </v-col>

            <v-col class="pb-0" cols="12" lg="6" md="6" sm="6">
              <v-textarea
                :label="'Ship For'"
                outlined
                v-model="myObj.ship_for"
              />
            </v-col>

            <v-col class="pb-0" cols="12" lg="6" md="6" sm="6">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.note')"
                outlined
                v-model="myObj.note"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>
      <v-btn color="green darken-2" @click="validate">
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import { draggable } from "@/api/customFunction";

const newUpData = () => {
  return {
    carton_num: "",
    customer_id: "",
    id: "",
    note: "",
    order_id: "",
    plan_receive: "",
    plan_receive_r1: "",
    plan_receive_r2: "",
    real_receive: "",
    send_date: "",
    send_goods: "",
    send_way: "",
    ship_for: "",
    shipping_cost: 0,
    shipping_cost1: 0,
    customer_info: "",
  };
};

const productObj = () => {
  return {
    id: "",
    number: "",
    net_weight: "",
    nick_name: "",
    gross_weight: "",
  };
};

export default {
  props: {
    dialog: Boolean,
    myObj: Object,
    orderId: String,
    commodity: String,
    SelectedOrder: Object,
    isBtnSearchOrder: Boolean,
    ship_way: Array,
  },
  components: {
    DialogTitle: () => import("@/components/dialogTitle"),
  },
  data() {
    return {
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      updata: newUpData(),
      datas: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "80",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product"),
          value: "nick_name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.qty1"),
          value: "number",
          width: "150",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.net_weight"),
          value: "net_weight",
          width: "150",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gross_weight"),
          value: "gross_weight",
          width: "150",
          sortable: false,
        },
      ],
      rule: {
        required: (v) => !!v || "Required",
      },
    };
  },
  methods: {
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    validate() {
      if (this.$refs.form.validate()) {
        var arr = [];

        this.myObj.products.forEach((p, k) => {
          var product = productObj();
          product.id = p.id;
          product.number = parseFloat(p.number);
          product.nick_name = p.nick_name;
          product.net_weight =
            p.net_weight == null ? 0 : parseFloat(p.gross_weight);
          product.gross_weight =
            p.gross_weight == null ? 0 : parseFloat(p.gross_weight);

          arr.push(product);
        });

        this.updata.carton_num = this.myObj.carton_num;
        this.updata.customer_id = this.myObj.customer_id;
        this.updata.id = "";
        this.updata.note = this.myObj.note;
        this.updata.order_id = this.myObj.id;
        this.updata.plan_receive = this.myObj.plan_receive;
        this.updata.plan_receive_r1 = this.myObj.plan_receive_r1;
        this.updata.plan_receive_r2 = this.myObj.plan_receive_r2;
        this.updata.real_receive = "";
        this.updata.send_date = this.myObj.send_date;
        this.updata.send_goods = JSON.stringify(arr);
        this.updata.send_way = this.myObj.send_way;
        this.updata.ship_for = this.myObj.ship_for;
        this.updata.shipping_cos = this.myObj.shipping_cost1;
        this.updata.shipping_cost = this.myObj.shipping_cost1;
        this.updata.shipping_cost1 = this.myObj.shipping_cost1;
        this.updata.customer_info = JSON.stringify(this.myObj.customer_info);

        this.$emit("handleData", this.updata);
      } else {
        console.log("false");
      }
    },
    handleDialogOrder() {
      this.$emit("selectOrder");
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    drag() {
      draggable();
    },
  },
  watch: {
    SelectedOrder() {
      if (this.SelectedOrder != undefined) {
        if (this.SelectedOrder.products != undefined) {
          this.SelectedOrder.products.forEach((p, k) => {
            p.itemNo = k + 1;
          });
        }
      }
    },
  },
};
</script>